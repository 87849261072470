import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Helmet from 'react-helmet'
import withStyles from '@material-ui/styles/withStyles'

import APaper from '../components/01-atoms/a-paper'
import AButton from '../components/01-atoms/a-button'
import MIconHeadlineTextLine from '../components/02-molecules/m-icon-headline-text-line'
import MImageText from '../components/02-molecules/m-image-text'
import SimpleTemplateWrapper from '../components/04-templates/t-simple-template-wrapper'

import IconDlvz from './../assets/svgs/about/icon-dlvz.svg'
import IconAddCompany from './../assets/svgs/about/icon-add-company.svg'
import IconEye from './../assets/svgs/about/icon-eye.svg'
import IconOpenBook from './../assets/svgs/about/icon-open-book.svg'
import IconNewsletter from './../assets/svgs/about/icon-newsletter.svg'
import IconFb from './../assets/svgs/about/icon-fb.svg'

import { companyInfo, aboutPage, globalNaming } from '../../site-config'

const styles = theme => ({
  anker_icon: {
    width: '192px',
    height: '192px',
    padding: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      width: '100px',
      height: '100px',
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4),
    },
  },
  anker_text: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontSize: '18px',
    hyphens: 'auto',
    [theme.breakpoints.up('md')]: {
      fontSize: '22px',
    },
    [theme.breakpoints.down('xs')]: {
      alignSelf: 'center',
      fontSize: '16px',
    },
  },
  anker_link: {
    textDecoration: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
    },
  },
  icon: {
    width: '80px',
    height: '80px',
    [theme.breakpoints.up('md')]: {
      width: '150px',
      height: '150px',
    },
  },
  divider: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
    },
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  infotext: {
    color: theme.customStyles.extraColors.lightGrey,
    fontSize: '14px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '16px',
      lineHeight: '25px',
    },
  },
  h3: {
    color: theme.customStyles.extraColors.lightGrey,
    fontSize: '25px',
    lineHeight: '33px',
  },
  iconsubline: {
    color: theme.customStyles.extraColors.lightGrey,
    fontWeight: 'bold',
  },
  iconnumber: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(20),
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.pxToRem(30),
    },
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(40),
    },
  },
})

/** AboutPage */
class AboutPage extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { classes, data } = this.props
    const addYourCompany = (
      <Img fluid={data.addYourCompany.childImageSharp.fluid} />
    )
    const chooseAPackage = (
      <Img fluid={data.chooseAPackage.childImageSharp.fluid} />
    )
    const presentYourCompany = (
      <Img fluid={data.presentYourCompany.childImageSharp.fluid} />
    )
    const showReferences = (
      <Img fluid={data.showReferences.childImageSharp.fluid} />
    )
    const magazin = <Img fluid={data.magazin.childImageSharp.fluid} />
    const contactData = <Img fluid={data.contactData.childImageSharp.fluid} />

    const editEntry = <Img fluid={data.editEntry.childImageSharp.fluid} />

    const ctaButton = (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={5}
      >
        <Helmet
          meta={[
            {
              name: 'description',
              content:
                'Sie haben begeisterte Kunden oder erfolgreiche Projekte? Dann nutzen Sie die Referenzen Ihres Firmeneintrags und zeigen Besuchern, was Sie geleistet haben.',
            },
          ]}
        />
        <Grid item>
          <AButton
            color="primary"
            className={classes.addCompanyButton}
            variant="contained"
            to="/register"
          >
            Firma hinzufügen
          </AButton>
        </Grid>
      </Grid>
    )

    return (
      <SimpleTemplateWrapper
        showHeadlineBar
        headline="Ihr Weg zu mehr Kundenkontakten"
      >
        <APaper>
          <Grid container spacing={5} justify="center">
            <Grid item xs={12} sm={4} md={3} align="center">
              <Link to="/about#about-dlvz" className={classes.anker_link}>
                <IconDlvz className={classes.anker_icon} />
                <Typography component="p" className={classes.anker_text}>
                  Was ist das {companyInfo.longName} {globalNaming.entryType}
                  verzeichnis?
                </Typography>
              </Link>
            </Grid>
            <Grid item xs={12} sm={4} md={3} align="center">
              <Link to="/about#add-company" className={classes.anker_link}>
                <IconAddCompany className={classes.anker_icon} />
                <Typography component="p" className={classes.anker_text}>
                  Wie kann ich meine Firma eintragen?
                </Typography>
              </Link>
            </Grid>
          </Grid>
        </APaper>
        <APaper id="about-dlvz">
          <Grid container spacing={5} justify="center">
            <Grid item sm={12} md={8}>
              <MIconHeadlineTextLine icon={<IconDlvz />}>
                <Typography variant="h2">
                  Was ist das {companyInfo.longName} {globalNaming.entryType}
                  verzeichnis
                </Typography>
                <Typography component="p">
                  Sie sind auf der Suche nach neuen potenziellen Kunden? Dann
                  präsentieren Sie Ihre Firma oder Dienstleistung im{' '}
                  {companyInfo.longName} {globalNaming.entryType}verzeichnis.
                  Nutzen Sie die Reichweite von Deutschlands großer{' '}
                  {aboutPage.keyword}-Plattform und zeigen Sie Ihren
                  potenziellen Kunden Ihre Produkte und Referenzen.
                </Typography>
              </MIconHeadlineTextLine>
            </Grid>
          </Grid>
          <Grid container spacing={5} justify="center">
            <Grid item sm={12} md={8}>
              <Typography component="p" paragraph className={classes.infotext}>
                Ihr Eintrag wird crossmedial über Websites und Print präsentiert
                und platziert Ihr Produkt oder Ihre Dienstleistung perfekt in
                der {aboutPage.keyword}-Zielgruppe.
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={5} justify="center">
            <Grid item xs={6} sm={3} md={2} align="center">
              <IconEye className={classes.icon} />
              <Typography component="p" className={classes.iconnumber}>
                {aboutPage.numbers[1]}
              </Typography>
              <Typography component="p" className={classes.iconsubline}>
                Page Impressions
                <br />
                pro Monat
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={2} align="center">
              <IconOpenBook className={classes.icon} />
              <Typography component="p" className={classes.iconnumber}>
                {aboutPage.numbers[2]}
              </Typography>
              <Typography component="p" className={classes.iconsubline}>
                Druckauflage
                <br />
                pro Heft
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={2} align="center">
              <IconNewsletter className={classes.icon} />
              <Typography component="p" className={classes.iconnumber}>
                {aboutPage.numbers[3]}
              </Typography>
              <Typography component="p" className={classes.iconsubline}>
                Newsletter
                <br />
                täglich
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3} md={2} align="center">
              <IconFb className={classes.icon} />
              <Typography component="p" className={classes.iconnumber}>
                {aboutPage.numbers[4]}
              </Typography>
              <Typography component="p" className={classes.iconsubline}>
                Facebook
                <br />
                Likes
              </Typography>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Typography variant="h3" align="center" className={classes.h3}>
                Die Vorteile Ihres Firmeneintrags im Überblick:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MImageText
                headline_text="Präsentieren Sie Ihr Unternehmen"
                image={presentYourCompany}
              >
                Veröffentlichen Sie Ihren Firmeneintrag inklusive aller Produkte
                und Dienstleistungen Ihres Portfolios. Verweisen Sie auf Ihre
                sozialen Kanäle und präsentieren Sie sich mit hochauflösenden
                Key Visuals.
              </MImageText>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MImageText
                headline_text="Zeigen Sie Ihre Referenzen"
                image={showReferences}
              >
                Sie haben begeisterte Kunden oder erfolgreich abgeschlossene
                Projekte? Dann nutzen Sie die Referenzen-Funktion an Ihrem
                Firmeneintrag und zeigen Besuchern Ihre beste Seite.
              </MImageText>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MImageText
                headline_text={`Erscheinen Sie in der ${companyInfo.longName}`}
                image={magazin}
              >
                Mit Ihrem Premium-Eintrag in der {companyInfo.longName}{' '}
                präsentieren Sie Ihr Unternehmen in jeder Ausgabe der passenden
                Zielgruppe.
              </MImageText>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MImageText
                headline_text="Einfach in Kontakt treten"
                image={contactData}
              >
                Erhalten Sie zusätzliche Anfragen über unser Firmenverzeichnis.
                Über einen prominenten Call-to-Action werden Besucher Ihres
                Eintrags zur Kontaktaufnahme animiert.
              </MImageText>
            </Grid>
          </Grid>
          {ctaButton}
        </APaper>
        <APaper id="add-company">
          <Grid container spacing={5} justify="center">
            <Grid item sm={12} md={8}>
              <MIconHeadlineTextLine icon={<IconAddCompany />}>
                <Typography variant="h2">
                  Wie kann ich meine Firma eintragen?
                </Typography>
                <Typography component="p">
                  In wenigen Schritten können Sie Ihre Firma im{' '}
                  {globalNaming.entryType}verzeichnis der {companyInfo.longName}{' '}
                  präsentieren.
                </Typography>
              </MIconHeadlineTextLine>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Grid container spacing={5} justify="center">
            <Grid item xs={12}>
              <Typography variant="h3" align="center" className={classes.h3}>
                In drei Schritten zur Eintragung
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MImageText
                headline_text="Paket auswählen"
                image={chooseAPackage}
              >
                Wählen Sie das passende Paket aus.
              </MImageText>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MImageText headline_text="Registrierung" image={addYourCompany}>
                Registrieren Sie sich im {globalNaming.entryType}verzeichnis der{' '}
                {companyInfo.longName}.
              </MImageText>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MImageText headline_text="Eintrag vornehmen" image={editEntry}>
                Stellen Sie sich nach Authentifizierung Ihres Accounts Ihren
                Eintrag mit vielfältigen Modulen zur Firmenpräsentation und
                Kundengewinnung zusammen.
              </MImageText>
            </Grid>
          </Grid>
          {ctaButton}
        </APaper>
      </SimpleTemplateWrapper>
    )
  }
}

AboutPage.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export default withStyles(styles)(AboutPage)

// eslint-disable-next-line
export const query = graphql`
  query GatsbyAboutImageQuery {
    addYourCompany: file(
      relativePath: { eq: "images/about/add-your-company.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 640, toFormat: PNG) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    chooseAPackage: file(
      relativePath: { eq: "images/about/choose-a-package.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 640, toFormat: PNG) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    contactData: file(relativePath: { eq: "images/about/contact-data.png" }) {
      childImageSharp {
        fluid(maxWidth: 640, toFormat: PNG) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    editEntry: file(relativePath: { eq: "images/about/edit-entry.png" }) {
      childImageSharp {
        fluid(maxWidth: 640, toFormat: PNG) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    magazin: file(relativePath: { eq: "images/about/magazin.png" }) {
      childImageSharp {
        fluid(maxWidth: 640, toFormat: PNG) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    presentYourCompany: file(
      relativePath: { eq: "images/about/present-your-company.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 640, toFormat: PNG) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    showReferences: file(
      relativePath: { eq: "images/about/show-references.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 640, toFormat: PNG) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`
