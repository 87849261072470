/**
 * MImageText
 *
 * @param {String} name of the icon.
 *
 */

import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/styles/withStyles'

import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  headline: {
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '25px',
    marginBottom: '10px',
    textAlign: 'center',
  },
  image: {
    width: '100%',
  },
  paragraph: {
    fontSize: '16px',
    lineHeight: '25px',
    color: theme.customStyles.extraColors.lightGrey,
  },
})

class MImageText extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { children, classes, headline_text, img_name, image } = this.props
    const img = img_name ? (
      <img src={img_name} className={classes.image} />
    ) : (
      image
    )
    const headline = headline_text ? (
      <Typography component="p" className={classes.headline}>
        {headline_text}
      </Typography>
    ) : (
      ''
    )
    return (
      <Fragment>
        {img}
        {headline}
        <Typography component="p" paragraph className={classes.paragraph}>
          {children}
        </Typography>
      </Fragment>
    )
  }
}

MImageText.propTypes = {
  children: PropTypes.node,
  headline_text: PropTypes.string,
  img_name: PropTypes.string,
  image: PropTypes.object,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(MImageText)
